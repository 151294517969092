import gif from "./images/gif.mp4";

export default function Gif() {
  return (
    <div>
      <div className="gifdiv">
        <video
          className="video"
          controls={false}
          autoPlay={true}
          loop={true}
          playsInline
          muted
        >
          <source src={gif} />
        </video>
      </div>
    </div>
  );
}
