import Header from "./Header";
import Logo from "./Logo";
import positron2 from "./images/positron2.png";
import positron3 from "./images/positron3.png";
import positron4 from "./images/positron4.png";
import alarme from "./images/alarme.gif";
import alarmef1 from "./images/alarmef1.png";
import alarmef2 from "./images/alarmef2.png";
import alarmef3 from "./images/alarmef3.png";
import alarmef from "./images/alarmef.gif";
import Promo from "./Promopage";
import Infos from "./Infos";
import Hr from "./Hr";
import Scroll from "./Scroll";
export default function Alarmes() {
  return (
    <div translate="no">
      <Header />
      <Logo />
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            ALARME AUTOMOTIVO POSITRON CYBER EXACT EX360
          </h2>

          <img className="alarme1" src={alarme}></img>

          <div className="divpics">
            <img className="pics" src={positron2}></img>
            <img className="pics" src={positron3}></img>
            <img className="pics" src={positron4}></img>
          </div>
          <h2 className="buy">R$ 314,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alarme automotivo positron cyber exact ex360."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Positron
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Cyber EX 360 US
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONTROLES:</h3> 2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TENSÃO DE OPERAÇÃO:</h3> 12 Volts
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">SENSOR DE PRESENÇA:</h3> Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM SIRENE:</h3> Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">SENSOR VOLUMÉTRICO:</h3> Sim
            </h3>
            <a
              href="https://www.youtube.com/watch?v=piRJcK6V3ZY"
              target="blank"
              className="instalar"
            >
              INSTALAÇÃO DO PRODUTO
            </a>
          </div>
          <Hr />
        </div>
      </div>

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            ALARME AUTOMOTIVO FKS FK902 UNIVERSAL
          </h2>
          <img className="alarme2" src={alarmef}></img>
          <div className="divpics">
            <img className="pics" src={alarmef1}></img>
            <img className="pics" src={alarmef2}></img>
            <img className="pics" src={alarmef3}></img>
          </div>
          <h2 className="buy">R$ 233,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alarme automotivo FKS FK902."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>FKS
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3> FK 902
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONTROLES:</h3> 2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TENSÃO DE OPERAÇÃO:</h3> 12 Volts
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">SENSOR DE PRESENÇA</h3> Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM SIRENE:</h3> Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">SENSOR VOLUMÉTRICO:</h3> Não
            </h3>
            <a
              href="https://www.youtube.com/watch?v=n_NQ2Nqwh-o"
              target="blank"
              className="instalar"
            >
              INSTALAÇÃO DO PRODUTO
            </a>
          </div>
        </div>
      </div>
      <Promo />

      <Infos />
      <Scroll />
    </div>
  );
}
