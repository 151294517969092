import Header from "./Header";
import Logo from "./Logo";

import Promo from "./Promopage";
import Infos from "./Infos";

import corneta1 from "./images/corneta1.png";
import corneta2 from "./images/corneta2.png";
import corneta3 from "./images/corneta3.png";
import cornetag from "./images/cornetag.gif";

import Scroll from "./Scroll";

export default function Cornetas() {
  return (
    <div translate="no">
      <Header />
      <Logo />
      <div className="fdivproductp">
        <div className="divproductp">
          <div className="divproductp">
            <h2 className="textproductp">
              {" "}
              BOCA CORNETA CANECO P/ DRIVER D405 305 EROS 4160 E QVS
            </h2>
            <img className="f10" src={cornetag}></img>
            <div className="divpics">
              <img className="pics" src={corneta1}></img>
              <img className="pics" src={corneta2}></img>
              <img className="pics" src={corneta3}></img>
            </div>
            <h2 className="buy">R$ 165,00</h2>
            <a
              href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar a boca de corneta caneco Aluminium D405."
              target="blank"
              className="buybtn"
            >
              COMPRAR PRODUTO
            </a>
            <div className="descrip">
              <h3 className="h3descrip">
                <h3 className="descripbold">MARCA:</h3>Aluminium
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">MODELO:</h3>Caneco
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">CONE:</h3>405-305
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">MATERIAL:</h3>Alumínio
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">ÂNGULO VERTICAL:</h3>360°
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">ÂNGULO HORIZONTAL:</h3>170°
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">DIÂMETRO GARGANTA:</h3>19.5CM
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">COMPRIMENTO X ALTURA:</h3>
                19.5CM X 19.5CM
              </h3>
            </div>
          </div>
        </div>
      </div>

      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
