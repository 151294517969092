import { useState } from "react";
import emailjs from "@emailjs/browser";
export default function Promo() {
  const [email, setEmail] = useState("");

  function sendEmail(e) {
    e.preventDefault();
    if (email === "") {
      alert("Preencha todos os campos.");
    }
    const templateParams = {
      email: email,
    };
    emailjs
      .send(
        "service_7y12x27",
        "template_et99ckp",
        templateParams,
        "eqOP8MK71jlev8uoM"
      )
      .then(
        (response) => {
          console.log("EMAIL ENVIADO", response.status, response.text);
          setEmail("");
        },
        (err) => {
          console.log("ERRO", err);
        }
      );
  }
  return (
    <form onSubmit={sendEmail} translate="no">
      <div>
        <div className="divpromop">
          <h2 className="promotext">
            CADASTRE-SE E RECEBA PROMOÇÕES EXCLUSIVAS!
          </h2>
        </div>
        <div className="divbtnpromo">
          <input
            placeholder="DIGITE SEU E-MAIL"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className="email"
          />

          <hr className="hrpromo"></hr>
          <button type="submit" className="btnpromo">
            CADASTRAR E-MAIL
          </button>
        </div>
      </div>
    </form>
  );
}
