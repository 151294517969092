import Header from "./Header";
import Logo from "./Logo";
import lampada1 from "./images/lampada1.png";
import lampada2 from "./images/lampada2.png";
import lampada3 from "./images/lampada3.png";
import lampadag from "./images/lampadag.gif";
import lampada4 from "./images/lampada4.png";
import lampada5 from "./images/lampada5.png";
import lampada6 from "./images/lampada6.png";
import lampadawg from "./images/lampadawg.gif";
import lampada7 from "./images/lampada7.png";
import lampada8 from "./images/lampada8.png";
import lampada9 from "./images/lampada9.png";
import lampadatg from "./images/lampadatg.gif";
import lampada10 from "./images/lampada10.png";
import lampada11 from "./images/lampada11.png";
import lampada12 from "./images/lampada12.png";
import lampadabg from "./images/lampadabg.gif";

import Promo from "./Promopage";
import Infos from "./Infos";
import Hr from "./Hr";
import Scroll from "./Scroll";
export default function Lampadas() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            SUPER LED HB4 HB3 10000 LUMENS COOLER 70W 6000 KELVIN
          </h2>
          <img className="lamp1" src={lampadag}></img>
          <div className="divpics">
            <img className="pics" src={lampada1}></img>
            <img className="pics" src={lampada2}></img>
            <img className="pics" src={lampada3}></img>
          </div>
          <h2 className="buy">R$ 269,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alarme automotivo positron cyber exact ex360."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>ASX
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>ULTRA LED HB3/HB4
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COR DA LUZ:</h3>BRANCO FRIO
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LEDS POR LÂMPADA:</h3>2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">FLUXO LUMINOSO:</h3>8000 LM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA CONSUMO:</h3>70W
            </h3>
          </div>
          <Hr />
        </div>
      </div>
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            LÂMPADA SUPER LED H1 H3 H7 H11 H16 H27 HB3 HB4 6000K 6000LM
          </h2>
          <img className="lamp2" src={lampadawg}></img>
          <div className="divpics">
            <img className="pics" src={lampada4}></img>
            <img className="pics" src={lampada5}></img>
            <img className="pics" src={lampada6}></img>
          </div>
          <h2 className="buy">R$ 155,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alarme automotivo positron cyber exact ex360."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>M7
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TEMPERATURA:</h3>6000K
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">FLUXO LUMINOSO:</h3>6000 LM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LEDS POR LÂMPADA:</h3>2
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA CONSUMO:</h3>35W
            </h3>
          </div>
          <Hr />
        </div>

        <div className="divproductp">
          <h2 className="textproductp">
            PAR LÀMPADAS H7 COMUM AUTOMOTIVA 55W CINDY
          </h2>
          <img className="lamp3" src={lampadatg}></img>
          <div className="divpics">
            <img className="pics" src={lampada7}></img>
            <img className="pics" src={lampada8}></img>
            <img className="pics" src={lampada9}></img>
          </div>
          <h2 className="buy">R$ 70,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o par de lâmpadas automotiva H7 CINOY."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>CINOY
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>HALÓGENA
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COR DA LUZ:</h3>AMARELO
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE:</h3>PAR
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3>55W
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">VOLTAGEM:</h3>12V
            </h3>
          </div>
          <Hr />
        </div>

        <div className="divproductp">
          <h2 className="textproductp">
            PAR LÂMPADAS AUTOMOTIVA SUPER BRANCA FAROL H16 8500K 19W 12V
          </h2>
          <img className="lamp4" src={lampadabg}></img>
          <div className="divpics">
            <img className="pics" src={lampada10}></img>
            <img className="pics" src={lampada11}></img>
            <img className="pics" src={lampada12}></img>
          </div>
          <h2 className="buy">R$ 95,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o par de lâmpadas automotiva super branca farol H16 TECHONE"
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Tech One
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>H16
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COR DA LUZ:</h3>Branco
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE:</h3>PAR
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TENSÃO DE OPERAÇÃO:</h3>12v
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3>19W
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
