import Header from "./Header";
import Logo from "./Logo";
import eros4 from "./images/eros4.png";
import eros5 from "./images/eros5.png";
import eros6 from "./images/eros6.png";
import erosg from "./images/erosg.gif";
import bomber2 from "./images/bomber2.png";
import bomber3 from "./images/bomber3.png";
import bomber4 from "./images/bomber4.png";
import erosgif from "./images/erosgif.gif";
import eros1 from "./images/eros1.png";
import eros2 from "./images/eros2.png";
import eros3 from "./images/eros3.png";
import up from "./images/up.gif";
import pioneergif from "./images/pioneergif.gif";
import pioneer1 from "./images/pioneer1.png";
import pioneer2 from "./images/pioneer2.png";
import pioneer3 from "./images/pioneer3.png";
import hurricane1 from "./images/hurricane1.png";
import hurricane2 from "./images/hurricane2.png";
import hurricane3 from "./images/hurricane3.png";
import hurricanegif from "./images/hurricanegif.gif";
import hurri1 from "./images/hurri1.png";
import hurri2 from "./images/hurri2.png";
import hurri3 from "./images/hurri3.png";
import hurrig from "./images/hurrig.gif";

import tweet7 from "./images/tweet7.png";
import tweet8 from "./images/tweet8.png";
import tweet9 from "./images/tweet9.png";
import tweetbg from "./images/tweetbg.gif";
import Promo from "./Promopage";
import Infos from "./Infos";
import Hr from "./Hr";
import Scroll from "./Scroll";

export default function Falantes() {
  return (
    <div translate="no">
      <Header />
      <Logo />
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            ALTO FALANTE SUBWOOFER BOMBER UPGRADE 12P 350W RMS 4 OHMS COR PRETO
          </h2>
          <img className="f1" src={up}></img>
          <div className="divpics">
            <img className="pics" src={bomber2}></img>
            <img className="pics" src={bomber3}></img>
            <img className="pics" src={bomber4}></img>
          </div>
          <h2 className="buy">R$ 342,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alto falante subwoofer bomber upgrade."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Bomber
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>Upgrade
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>1.04.102
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Preto
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">DIMENSÃO:</h3>31x15cm
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO:</h3>Subwoofer
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VIAS:</h3>1
            </h3>{" "}
            <h3 className="h3descrip">
              <h3 className="descripbold">BOBINAS:</h3>1
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3>350W
            </h3>
          </div>
          <Hr />
        </div>

        <div className="divproductp">
          <h2 className="textproductp">
            ALTO FALANTE WOOFER EROS 15 POLEGADAS 800W RMS E-815 SDS 4 OHMS COR
            PRETO
          </h2>
          <img className="f2" src={erosgif}></img>
          <div className="divpics">
            <img className="pics" src={eros1}></img>
            <img className="pics" src={eros2}></img>
            <img className="pics" src={eros3}></img>
          </div>
          <h2 className="buy">R$ 1224,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alto falante woofer EROS E-815."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Eros
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>Woofer
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>E-15 SDS 2.7K
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">DIÂMETRO:</h3>15'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO:</h3>Subwoofer
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VIAS:</h3>1
            </h3>{" "}
            <h3 className="h3descrip">
              <h3 className="descripbold">BOBINAS:</h3>1
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3>135W
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA MÁXIMA:</h3>270W
            </h3>
          </div>
          <Hr />
        </div>

        <div className="divproductp">
          <h2 className="textproductp">
            EROS E-15 SDS 2.7K WOOFER 1350 RMS 15 POLEGADAS
          </h2>
          <img className="f3" src={erosg}></img>
          <div className="divpics">
            <img className="pics" src={eros4}></img>
            <img className="pics" src={eros5}></img>
            <img className="pics" src={eros6}></img>
          </div>
          <h2 className="buy">R$ 1439,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alto falante woofer EROS E-15."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Eros
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>Woofer
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>E-15 SDS 2.7K
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">DIÂMETRO:</h3>
              15'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO:</h3>Woofer
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VIAS:</h3>1
            </h3>{" "}
            <h3 className="h3descrip">
              <h3 className="descripbold">BOBINAS:</h3>1
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3>135W
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA MÁXIMA:</h3>270W
            </h3>
          </div>
          <Hr />
        </div>

        <div className="divproductp">
          <h2 className="textproductp">
            ALTO FALANTE SUBWOOFER SUB PIONEER TS-W3060BR 350 WRMS 12 POLEGADAS
          </h2>
          <img className="f4" src={pioneergif}></img>
          <div className="divpics">
            <img className="pics" src={pioneer1}></img>
            <img className="pics" src={pioneer2}></img>
            <img className="pics" src={pioneer3}></img>
          </div>
          <h2 className="buy">R$ 434,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alto falante subwoofer PIONEER TS-W3060BR."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Pioneer
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>TS
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>TS-W3060BR
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>P
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LARGURA:</h3>12'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>12'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO:</h3>Quadriaxial
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VIAS:</h3>4
            </h3>{" "}
            <h3 className="h3descrip">
              <h3 className="descripbold">BOBINAS:</h3>1
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3>280W
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA MÁXIMA:</h3>360w
            </h3>
          </div>
          <Hr />
        </div>

        <div className="divproductp">
          <h2 className="textproductp">
            KIT ALTO FALANTES HURRICANE TRIAK 6 CM69 280W RMS COR PRETO E AZUL
          </h2>
          <img className="f5" src={hurricanegif}></img>
          <div className="divpics">
            <img className="pics" src={hurricane1}></img>
            <img className="pics" src={hurricane2}></img>
            <img className="pics" src={hurricane3}></img>
          </div>
          <h2 className="buy">R$ 291,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o kit alto falantes HURRICANE TRIAK 6 CM69."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>

          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Hurricane
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>Triak
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>CM69 TRIAK 6
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Preto e azul
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>165MMW
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE:</h3>4 Unidades
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO:</h3>Subwoofer
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VIAS:</h3>2
            </h3>{" "}
            <h3 className="h3descrip">
              <h3 className="descripbold">BOBINAS:</h3>1
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3>180W
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA MÁXIMA:</h3>360W
            </h3>
          </div>
          <Hr />
          <div className="divproductp">
            <h2 className="textproductp">
              PAR ALTO FALANTES HURRICANE PLATINO QUADRIAXIAL QR694 6X9 300W RMS
            </h2>
            <img className="f6" src={hurrig}></img>
            <div className="divpics">
              <img className="pics" src={hurri1}></img>
              <img className="pics" src={hurri2}></img>
              <img className="pics" src={hurri3}></img>
            </div>
            <h2 className="buy">R$ 288,00</h2>
            <a
              href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o par de alto falantes quadriaxial HURRICANE QR694."
              target="blank"
              className="buybtn"
            >
              COMPRAR PRODUTO
            </a>
            <div className="descrip">
              <h3 className="h3descrip">
                <h3 className="descripbold">MARCA:</h3>Hurricane
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">MODELO:</h3>QR69
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">LARGURA:</h3>6'
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">COMPRIMENTO:</h3>9'
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">COR:</h3>Cinza/Preto
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">QUANTIDADE:</h3>2 Unidades
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
                Carro/Caminhonete
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">TIPO:</h3>Quadriaxial
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">VIAS:</h3>4
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">BOBINAS:</h3>1
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">POTÊNCIA PAR:</h3>300W
              </h3>
            </div>
            <Hr />
          </div>

          <div className="divproductp">
            <h2 className="textproductp"> ALTO FALANTE JBL ST200 COR PRETO</h2>
            <img className="f9" src={tweetbg}></img>
            <div className="divpics">
              <img className="pics" src={tweet7}></img>
              <img className="pics" src={tweet8}></img>
              <img className="pics" src={tweet9}></img>
            </div>
            <h2 className="buy">R$ 183,00</h2>
            <a
              href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alto falante JBL ST200."
              target="blank"
              className="buybtn"
            >
              COMPRAR PRODUTO
            </a>
            <div className="descrip">
              <h3 className="h3descrip">
                <h3 className="descripbold">MARCA:</h3>JBL
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">LINHA:</h3>Super tweeter
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">MODELO:</h3>ST200
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">COR:</h3>Preto
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">LARGURA:</h3>48'
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">COMPRIMENTO:</h3>48'
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
                Carro/Caminhonete
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">TIPO:</h3>Super tweeter
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">BOBINAS:</h3>1
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">POTÊNCIA:</h3>100W
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">POTÊNCIA MÁXIMA:</h3>300W
              </h3>
            </div>
          </div>
        </div>
      </div>

      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
