import Header from "./Header";
import Logo from "./Logo";
import pelicula1 from "./images/pelicula1.png";
import pelicula2 from "./images/pelicula2.png";
import pelicula3 from "./images/pelicula3.png";
import peliculag from "./images/peliculag.gif";
import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";

export default function Pelicula() {
  return (
    <div translate="no">
      <Header />
      <Logo />
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            PELÍCULA WORLD FILM BOBINA 15M X 1,52M G5/G20/G35 TINTADA
          </h2>
          <img className="pe" src={peliculag}></img>
          <div className="divpics">
            <img className="pics" src={pelicula1}></img>
            <img className="pics" src={pelicula2}></img>
            <img className="pics" src={pelicula3}></img>
          </div>
          <h2 className="buy">R$ 278,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar a película WORLD FILM."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>World Film
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Insulfilm
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">OPÇÕES:</h3>G5/20G/G35
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">METRAGEM:</h3>15M X 1,52M
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
