import Header from "./Header";
import Logo from "./Logo";

import conversor1 from "./images/conversor1.png";
import conversor2 from "./images/conversor2.png";
import conversor3 from "./images/conversor3.png";

import conversorg from "./images/conversorg.gif";
import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";
import Hr from "./Hr";
export default function Conversor() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            CONVERSOR FIO P/ RCA JFA REMOTO CD/DVD/RÁDIO
          </h2>
          <img className="convers" src={conversorg}></img>
          <div className="divpics">
            <img className="pics" src={conversor1}></img>
            <img className="pics" src={conversor2}></img>
            <img className="pics" src={conversor3}></img>
          </div>
          <h2 className="buy">R$ 135,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o conversor para rca JFA."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>JFA
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>RCA Slim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM RÁDIO:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM ENTRADA AUXILIAR:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM BLUETOOTH:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM CONTROLE REMOTO:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM CABO:</h3>Sim
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
