export default function Message() {
  return (
    <div translate="no" className="titledivp">
      <h2 className="ttextm">
        Escolha JT Quality para todas as suas necessidades de som e acessórios
        automotivos
      </h2>
    </div>
  );
}
