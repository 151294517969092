import { Link } from "react-router-dom";
export default function Lancamento() {
  return (
    <div translate="no">
      <div className="divlancamento">
        <Link className="producttext" to="/produtos">
          VISUALIZAR LANÇAMENTOS
        </Link>
      </div>
    </div>
  );
}
