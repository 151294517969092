export default function Title() {
  return (
    <div translate="no" className="titlediv">
      <h2 className="ttext">
        Qualidade sonora e acessórios automotivos de ponta, a apenas um clique
        de distância
      </h2>
    </div>
  );
}
