import Header from "./Header";
import Logo from "./Logo";
import multi1 from "./images/multi1.png";
import multi2 from "./images/multi2.png";
import multi3 from "./images/multi3.png";
import multig from "./images/multig.gif";
import multi4 from "./images/multi4.png";
import multi5 from "./images/multi5.png";
import multi6 from "./images/multi6.png";
import multigif from "./images/multigif.gif";
import multi7 from "./images/multi7.png";
import multi8 from "./images/multi8.png";
import multi9 from "./images/multi9.png";
import multiandroid from "./images/multiandroid.gif";
import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";
import Hr from "./Hr";
export default function Multimidia() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            MULTIMIDIA MP5 E-TECH PREMIUM SISTEMA ANDROID GPS/BT
          </h2>
          <img className="multi1" src={multig}></img>
          <div className="divpics">
            <img className="pics" src={multi1}></img>
            <img className="pics" src={multi2}></img>
            <img className="pics" src={multi3}></img>
          </div>
          <h2 className="buy">R$ 647,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o multimídia E-TECH MP5."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>E-Tech
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3> MP5 Premium BT/AUX/GPS
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TAMANHO DE TELA:</h3> 0.178M
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COM GPS:</h3> SIM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM ENTRADA USB:</h3> Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM BLUETOOTH:</h3> Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM FUNÇÃO DE TV:</h3> Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM TELA TOUCH:</h3> Sim
            </h3>
          </div>
          <Hr />
        </div>
        <div className="divproductp">
          <h2 className="textproductp">
            CENTRAL MULTIMÍDIA FIRST OPTION 7810H C/ ESPELHAMENTO E BLUETOOTH
          </h2>
          <img className="multi2" src={multigif}></img>
          <div className="divpics">
            <img className="pics" src={multi4}></img>
            <img className="pics" src={multi5}></img>
            <img className="pics" src={multi6}></img>
          </div>
          <h2 className="buy">R$ 303,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o central multimídia FIRST OPTION 7810H"
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>First Option
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3> Centrais Multimídia
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3> 7810H
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3> Preto
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TAMANHO DA TELA:</h3> 7'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">ALTURA:</h3> 10CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3> 5CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LARGURA:</h3> 18CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM GPS:</h3> Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM ENTRADA USB:</h3> Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM BLUETOOTH:</h3> Sim
            </h3>
          </div>
          <Hr />
        </div>
        <div className="divproductp">
          <h2 className="textproductp">
            MULTIMÍDIA ANDROID 7 POLEGADAS ESPELHAMENTO GPS USB 1GB
          </h2>
          <img className="multi3" src={multiandroid}></img>
          <div className="divpics">
            <img className="pics" src={multi7}></img>
            <img className="pics" src={multi8}></img>
            <img className="pics" src={multi9}></img>
          </div>
          <h2 className="buy">R$ 499,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o multimídia android H-TECH HT-6023."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>

          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3> H-Tech
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3> HT-6023
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3> HT-6023 Universal
              BT/AUX/GPS
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TAMANHO DE TELA:</h3> 7'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">ALTURA:</h3> 10CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3> 12CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LARGURA:</h3> 18CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM GPS:</h3> Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM ENTRADA USB:</h3> Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM BLUETOOTH:</h3> Sim
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
