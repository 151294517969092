import Header from "./Header";
import Logo from "./Logo";
import camera1 from "./images/camera1.png";
import camera2 from "./images/camera2.png";
import camera3 from "./images/camera3.png";
import camerag from "./images/camerag.gif";

import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";

export default function Cameras() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            CÂMERA RÉ AUTOMOTIVA INFRAVERMELHO E VISÃO NOTURNA
          </h2>
          <img className="cam" src={camerag}></img>
          <div className="divpics">
            <img className="pics" src={camera1}></img>
            <img className="pics" src={camera2}></img>
            <img className="pics" src={camera3}></img>
          </div>
          <h2 className="buy">R$ 135,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar a câmera de ré automotiva inframervelho e visão norturna colorida."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Câmera ré universal
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Câmera ré visão noturna
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">NÚMERO DE PEÇA:</h3>0627
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VOLTAGEM:</h3>12V
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">ÂNGULO DE VISÃO:</h3>170'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">RESOLUÇÃO DE VÍDEO:</h3>420TVL
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">SENSOR DE MOVIMENTO:</h3> Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">DIREÇÃO ASSISTIDA:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">WI-FI:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM BLUETOOTH:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM MICROFONE:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">A PROVA D'ÁGUA:</h3>Sim
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
