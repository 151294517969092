import Header from "./Header";
import Logo from "./Logo";

import tweet4 from "./images/tweet4.png";
import tweet5 from "./images/tweet5.png";
import tweet6 from "./images/tweet6.png";
import tweetg from "./images/tweetg.gif";

import Promo from "./Promopage";
import Infos from "./Infos";
import tweet10 from "./images/tweet10.png";
import tweet11 from "./images/tweet11.png";
import tweet12 from "./images/tweet12.png";
import giforion from "./images/giforion.gif";

import Hr from "./Hr";
import Scroll from "./Scroll";

export default function Tweets() {
  return (
    <div translate="no">
      <Header />
      <Logo />
      <div className="fdivproductp">
        <div className="divproductp">
          <div className="divproductp">
            <h2 className="textproductp">
              {" "}
              ALTO FALANTE SUPER TWEETER JBL ST400 COR BRANCO 120MM X 140MM
            </h2>
            <img className="t1" src={tweetg}></img>
            <div className="divpics">
              <img className="pics" src={tweet4}></img>
              <img className="pics" src={tweet5}></img>
              <img className="pics" src={tweet6}></img>
            </div>
            <h2 className="buy">R$ 314,00</h2>
            <a
              href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alto falante tweeter JBL ST400."
              target="blank"
              className="buybtn"
            >
              COMPRAR PRODUTO
            </a>
            <div className="descrip">
              <h3 className="h3descrip">
                <h3 className="descripbold">MARCA:</h3>JBL
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">LINHA:</h3>Trio
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">MODELO:</h3>ST400
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">COR:</h3>Branco
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">LARGURA:</h3>120MM
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">COMPRIMENTO:</h3>140MM
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
                Carro/Caminhonete
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">TIPO:</h3>Super tweeter
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">VIAS:</h3>1
              </h3>{" "}
              <h3 className="h3descrip">
                <h3 className="descripbold">BOBINAS:</h3>1
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">POTÊNCIA:</h3>150W
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">POTÊNCIA MÁXIMA:</h3>300W
              </h3>
            </div>
            <Hr />
          </div>

          <div className="divproductp">
            <h2 className="textproductp">
              {" "}
              SUPER TWEETER TSR 4200 ORION FENÓLICO 120W RMS 8 OHMS
            </h2>
            <img className="t2" src={giforion}></img>
            <div className="divpics">
              <img className="pics" src={tweet10}></img>
              <img className="pics" src={tweet11}></img>
              <img className="pics" src={tweet12}></img>
            </div>
            <h2 className="buy">R$ 86,00</h2>
            <a
              href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alto falante super tweeter ORION TSR 4200."
              target="blank"
              className="buybtn"
            >
              COMPRAR PRODUTO
            </a>
            <div className="descrip">
              <h3 className="h3descrip">
                <h3 className="descripbold">MARCA:</h3>TSR Orion
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">LINHA:</h3>TSR
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">MODELO:</h3>TSR 4200
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">COR:</h3>Preto
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
                Carro/Caminhonete
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">TIPO:</h3>Tweeter
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">POTÊNCIA:</h3>120W
              </h3>
            </div>
          </div>
        </div>
      </div>

      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
