import mult from "./images/mult.mp4";

export default function Mult() {
  return (
    <div>
      <div className="gifdiv">
        <video
          className="video"
          controls={false}
          autoPlay={true}
          loop={true}
          playsInline
          muted
        >
          <source src={mult} />
        </video>
      </div>
    </div>
  );
}
