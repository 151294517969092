import Header from "./Header";
import Logo from "./Logo";
import pioneer4 from "./images/pioneer4.png";
import pioneer5 from "./images/pioneer5.png";
import pioneer6 from "./images/pioneer6.png";
import pioneerg from "./images/pioneerg.gif";
import pioneer7 from "./images/pioneer7.png";
import pioneer8 from "./images/pioneer8.png";
import pioneer9 from "./images/pioneer9.png";
import pioneergr from "./images/pioneergr.gif";

import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";
import Hr from "./Hr";
export default function Radio() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            RÁDIO PIONEER MP3 MVH-S218 USB/BT AM/FM
          </h2>
          <img className="alarme" src={pioneerg}></img>
          <div className="divpics">
            <img className="pics" src={pioneer4}></img>
            <img className="pics" src={pioneer5}></img>
            <img className="pics" src={pioneer6}></img>
          </div>
          <h2 className="buy">R$ 586,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o rádio PIONEER MVH-S218."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Pioneer
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>MVH
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>S218BT
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3> Preto
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM ENTRADA USB:</h3> Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM BLUETOOTH:</h3> Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM ENTRADA AUXILIAR:</h3> Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM LEITOR SD:</h3> Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM CONTROLE REMOTO:</h3>Não
            </h3>
          </div>
          <Hr />
        </div>
      </div>

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            RADIO PIONEER MVH X3000 C/ USB/BLUETOOTH AM/FM
          </h2>
          <img className="radio2" src={pioneergr}></img>
          <div className="divpics">
            <img className="pics" src={pioneer7}></img>
            <img className="pics" src={pioneer8}></img>
            <img className="pics" src={pioneer9}></img>
          </div>
          <h2 className="buy">R$ 681,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o RÁDIO PIONEER MVH X3000."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Pioneer
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>MVH
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>X3000
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Preto
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM ENTRADA USB:</h3>Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM BLUETOOTH:</h3>Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM ENTRADA AUXILIAR:</h3>Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM LEITOR SD:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM CONTROLE REMOTO:</h3>Sim
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
