import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import App from "../App";

import Falantes from "../Falantes";
import Alarmes from "../Alarmes";
import Cabos from "../Cabos";
import Multimidia from "../Multimidia";
import Tapetes from "../Tapetes";
import Tomadas from "../Tomadas";

import Bloqueador from "../Bloqueador";
import Cameras from "../Cameras";
import Espelhos from "../Espelhos";
import Caixa from "../Caixa";
import Sensor from "../Sensor";
import Radio from "../Radio";
import Lampadas from "../Lampadas";
import Pelicula from "../Pelicula";
import Conversor from "../Conversor";
import Modulos from "../Modulos";
import Produtopage from "../Produtopage";
import Tweets from "../Tweets";
import Driver from "../Driver";

import Cornetas from "../Cornetas";

const Routering = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/produtos" element={<Produtopage />} />
        <Route path="/falantes" element={<Falantes />} />
        <Route path="/alarmes" element={<Alarmes />} />
        <Route path="/cabos" element={<Cabos />} />
        <Route path="/multimidia" element={<Multimidia />} />{" "}
        <Route path="/tapetes" element={<Tapetes />} />
        <Route path="/tomadas" element={<Tomadas />} />
        <Route path="/bloqueadores" element={<Bloqueador />} />
        <Route path="/cameras" element={<Cameras />} />
        <Route path="/espelhos" element={<Espelhos />} />
        <Route path="/caixas" element={<Caixa />} />
        <Route path="/sensores" element={<Sensor />} />
        <Route path="/radios" element={<Radio />} />
        <Route path="/lampadas" element={<Lampadas />} />
        <Route path="/peliculas" element={<Pelicula />} />
        <Route path="/conversor" element={<Conversor />} />{" "}
        <Route path="/modulos" element={<Modulos />} />
        <Route path="/tweets" element={<Tweets />} />
        <Route path="/drivers" element={<Driver />} />
        <Route path="/cornetas" element={<Cornetas />} />
      </Routes>
    </Router>
  );
};
export default Routering;
