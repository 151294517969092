import Header from "./Header";
import Logo from "./Logo";

import modulo4 from "./images/modulo4.png";
import modulo5 from "./images/modulo5.png";
import modulo6 from "./images/modulo6.png";

import modulogif from "./images/modulogif.gif";
import modulog from "./images/modulog.gif";
import modulo1 from "./images/modulo1.png";
import modulo2 from "./images/modulo2.png";
import modulo3 from "./images/modulo3.png";
import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";
import Hr from "./Hr";
export default function Modulos() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            MÓDULO SOM AUTOMOTIVO TARAMPS HD 2 OHMS AMPLIFICADOR 3000W
          </h2>
          <img className="mod1" src={modulog}></img>
          <div className="divpics">
            <img className="pics" src={modulo1}></img>
            <img className="pics" src={modulo2}></img>
            <img className="pics" src={modulo3}></img>
          </div>
          <h2 className="buy">R$ 673,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o módulo de som automotivo TARAMPS HD3000."
            target="blank"
            className="buybtn"
          >
            {" "}
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Taramps
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA: HD</h3>
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>HD3000
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Branco
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>{" "}
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3> 12,6V
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE DE CANAIS:</h3>1
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CLASSE:</h3>D
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">ORIGEM:</h3>Brasil
            </h3>
          </div>
          <Hr />
        </div>
      </div>
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            MÓDULO SOM AUTOMOTIVO TARAMPS TS 400X4 400W 2 OHMS RCA AMPLIFICADOR
          </h2>
          <img className="mod2" src={modulogif}></img>
          <div className="divpics">
            <img className="pics" src={modulo4}></img>
            <img className="pics" src={modulo5}></img>
            <img className="pics" src={modulo6}></img>
          </div>
          <h2 className="buy">R$ 286,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o módulo de som TARAMPS TS400X4."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Taramps
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>TS
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>TS400
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Branco
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>{" "}
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3> 13,8V
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE DE CANAIS:</h3>4
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CLASSE:</h3>D
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">ORIGEM:</h3>Brasil
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
