import Header from "./Header";
import Logo from "./Logo";
import espelho1 from "./images/espelho1.png";
import espelho2 from "./images/espelho2.png";
import espelho3 from "./images/espelho3.png";
import espelhog from "./images/espelhog.gif";

import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";

export default function Espelhos() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            RETROVISOR LCD C/ CÂMERA FRONTAL/RÉ E GRAVAÇÃO
          </h2>
          <img className="esp" src={espelhog}></img>
          <div className="divpics">
            <img className="pics" src={espelho1}></img>
            <img className="pics" src={espelho2}></img>
            <img className="pics" src={espelho3}></img>
          </div>
          <h2 className="buy">R$ 289,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o retrovisor lcd com câmera frontal/ré e gravação KNUP."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
        </div>
        <div className="descrip">
          <h3 className="h3descrip">
            <h3 className="descripbold">MARCA:</h3>Knup
          </h3>

          <h3 className="h3descrip">
            <h3 className="descripbold">MODELO:</h3>KP-S103
          </h3>

          <h3 className="h3descrip">
            <h3 className="descripbold">TIPO DE VEÍCULO:</h3>Carro/Caminhonete
          </h3>

          <h3 className="h3descrip">
            <h3 className="descripbold">ÂNGULO DE VISÃO FRONTAL:</h3>170°
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">ÂNGULO DE VISÃO TRASEIRA:</h3>120°
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">RESOLUÇÃO DE VÍDEO:</h3>1080P
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">ARMAZENAMENTO SD:</h3>Sim
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">GRAVAÇÃO EM LOOP:</h3>Sim
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">SENSOR DE MOVIMENTO:</h3>Sim
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">COM MICROFONE:</h3>Sim
          </h3>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
