import Header from "./Header";
import Logo from "./Logo";
import tapete3 from "./images/tapete3.png";
import tapete4 from "./images/tapete4.png";
import tapete5 from "./images/tapete5.png";
import tapeteg from "./images/tapeteg.gif";
import multig from "./images/multig.gif";
import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";

export default function Tapetes() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            JOGO TAPETES AUTOMOTIVO SPORT C/ LOGO TODAS AS MARCAS
          </h2>
          <img className="tapetes1" src={tapeteg}></img>
          <div className="divpics">
            <img className="pics" src={tapete3}></img>
            <img className="pics" src={tapete4}></img>
            <img className="pics" src={tapete5}></img>
          </div>
          <h2 className="buy">R$ 150,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o jogo de tapetes automotivo universal."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Universal
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>
              Chevrolet/Fiat/Ford/VW
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">NÚMERO DE PEÇAS:</h3>4
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Preto
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MATERIAL:</h3>Borracha
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
