import Header from "./Header";
import Logo from "./Logo";
import driver1 from "./images/driver1.png";
import driver2 from "./images/driver2.png";
import driver3 from "./images/driver3.png";
import driverg from "./images/driverg.gif";
import Promo from "./Promopage";
import Infos from "./Infos";
import driver4 from "./images/driver4.png";
import driver5 from "./images/driver5.png";
import driver6 from "./images/driver6.png";
import drivergif from "./images/drivergif.gif";

import Scroll from "./Scroll";
import Hr from "./Hr";

export default function Driver() {
  return (
    <div translate="no">
      <Header />
      <Logo />
      <div className="fdivproductp">
        <div className="divproductp">
          <div className="divproductp">
            <h2 className="textproductp">DRIVER CORNETEIRA CONE CHAMPION</h2>
            <img className="f7" src={driverg}></img>
            <div className="divpics">
              <img className="pics" src={driver1}></img>
              <img className="pics" src={driver2}></img>
              <img className="pics" src={driver3}></img>
            </div>
            <h2 className="buy">R$ 90,00</h2>
            <a
              href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o driver corneteira cone CHAMPION."
              target="blank"
              className="buybtn"
            >
              COMPRAR PRODUTO
            </a>
            <div className="descrip">
              <h3 className="h3descrip">
                <h3 className="descripbold">MARCA:</h3>Champion
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">MODELO:</h3>DRV-500
              </h3>
              <h3 className="h3descrip">
                <h3 className="descripbold">COR:</h3>Preto
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">DIÂMETRO:</h3>4'
              </h3>

              <h3 className="h3descrip">
                <h3 className="descripbold">POTÊNCIA:</h3>100W
              </h3>
            </div>
            <Hr />
            <div className="divproductp">
              <h2 className="textproductp">
                {" "}
                DRIVER JBL SELENIUM D405 100W RMS 8 OHMS
              </h2>
              <img className="fjb" src={drivergif}></img>
              <div className="divpics">
                <img className="pics" src={driver4}></img>
                <img className="pics" src={driver5}></img>
                <img className="pics" src={driver6}></img>
              </div>
              <h2 className="buy">R$ 775,00</h2>
              <a
                href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o driver JBL Selenium D405"
                target="blank"
                className="buybtn"
              >
                COMPRAR PRODUTO
              </a>
              <div className="descrip">
                <h3 className="h3descrip">
                  <h3 className="descripbold">MARCA:</h3>JBL
                </h3>

                <h3 className="h3descrip">
                  <h3 className="descripbold">MODELO:</h3>D405
                </h3>

                <h3 className="h3descrip">
                  <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
                </h3>
                <h3 className="h3descrip">
                  <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
                  Carro/Caminhonete
                </h3>

                <h3 className="h3descrip">
                  <h3 className="descripbold">MATERIAL:</h3>Alumínio
                </h3>
                <h3 className="h3descrip">
                  <h3 className="descripbold">DIÂMETRO:</h3>2'
                </h3>
                <h3 className="h3descrip">
                  <h3 className="descripbold">POTÊNCIA:</h3>
                  160W
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
