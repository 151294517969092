import Dropdown from "react-bootstrap/Dropdown";

function Select() {
  return (
    <div translate="no" className="divselect">
      <Dropdown>
        <Dropdown.Toggle
          className="toggle"
          variant="success"
          id="dropdown-basic"
        >
          PRODUTOS
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item className="dropmenu" href="/alarmes">
            ALARMES
          </Dropdown.Item>

          <Dropdown.Item className="dropmenu" href="/bloqueadores">
            BLOQUEADORES
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/cabos">
            CABOS
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/caixas">
            CAIXAS
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/cameras">
            CÂMERAS
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/conversor">
            CONVERSORES
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/cornetas">
            CORNETAS
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/drivers">
            DRIVER
          </Dropdown.Item>

          <Dropdown.Item className="dropmenu" href="/falantes">
            FALANTES
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/lampadas">
            LÂMPADAS
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/modulos">
            MÓDULOS
          </Dropdown.Item>

          <Dropdown.Item className="dropmenu" href="/multimidia">
            MULTIMÍDIAS
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/peliculas">
            PELÍCULAS
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/radios">
            RÁDIOS
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/espelhos">
            RETROVISORES
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/sensores">
            SENSORES
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/tapetes">
            TAPETES
          </Dropdown.Item>
          <Dropdown.Item className="dropmenu" href="/tomadas">
            TOMADAS
          </Dropdown.Item>

          <Dropdown.Item className="dropmenu" href="/tweets">
            TWEETER
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default Select;
