import pioneergif from "./images/pioneergif.gif";
import pioneer1 from "./images/pioneer1.png";
import pioneer2 from "./images/pioneer2.png";
import pioneer3 from "./images/pioneer3.png";
import tweet4 from "./images/tweet4.png";
import tweet5 from "./images/tweet5.png";
import tweet6 from "./images/tweet6.png";
import tweetg from "./images/tweetg.gif";
import modulogif from "./images/modulogif.gif";
import modulo4 from "./images/modulo4.png";
import modulo5 from "./images/modulo5.png";
import modulo6 from "./images/modulo6.png";
import Header from "./Header";
import Logo from "./Logo";
import Promo from "./Promo";
import Infos from "./Infos";
import Scroll from "./Scroll";
import Hr from "./Hr";

export default function Produtopage() {
  return (
    <div translate="no">
      <Header />
      <Logo />
      <div className="divproductp">
        <h2 className="textproductp">
          ALTO FALANTE SUBWOOFER SUB PIONEER TS-W3060BR 350WRMS 12 POLEGADAS
        </h2>
        <img className="f4" src={pioneergif}></img>
        <div className="divpics">
          <img className="pics" src={pioneer1}></img>
          <img className="pics" src={pioneer2}></img>
          <img className="pics" src={pioneer3}></img>
        </div>
        <h2 className="buy">R$ 434,00</h2>
        <a
          href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alto falante subwoofer PIONEER TS-W3060BR."
          target="blank"
          className="buybtn"
        >
          COMPRAR PRODUTO
        </a>
        <div className="descrip">
          <h3 className="h3descrip">
            <h3 className="descripbold">MARCA:</h3>Pioneer
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">LINHA:</h3>TS
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">MODELO:</h3>TS-W3060BR
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">COR:</h3>P
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">LARGURA:</h3>12'
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">COMPRIMENTO:</h3>12'
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
            Carro/Caminhonete
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">TIPO:</h3>Quadriaxial
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">VIAS:</h3>4
          </h3>{" "}
          <h3 className="h3descrip">
            <h3 className="descripbold">BOBINAS:</h3>1
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">POTÊNCIA:</h3>280W
          </h3>
          <h3 className="h3descrip">
            <h3 className="descripbold">POTÊNCIA MÁXIMA:</h3>360w
          </h3>
        </div>
        <Hr />
        <div className="divproductp">
          <h2 className="textproductp">
            {" "}
            ALTO FALANTE SUPER TWEETER JBL ST400 COR BRANCO 120MM X 140MM
          </h2>
          <img className="f8" src={tweetg}></img>
          <div className="divpics">
            <img className="pics" src={tweet4}></img>
            <img className="pics" src={tweet5}></img>
            <img className="pics" src={tweet6}></img>
          </div>
          <h2 className="buy">R$ 314,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o alto falante tweeter JBL ST400."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>JBL
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>Trio
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>ST400
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Branco
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LARGURA:</h3>120MM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>140MM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE:</h3>1 Unidade
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO:</h3>Super tweeter
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VIAS:</h3>1
            </h3>{" "}
            <h3 className="h3descrip">
              <h3 className="descripbold">BOBINAS:</h3>1
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3>150W
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA MÁXIMA:</h3>300W
            </h3>
          </div>
        </div>
        <Hr />
        <div className="divproductp">
          <h2 className="textproductp">
            MÓDULO SOM AUTOMOTIVO TARAMPS TS 400X4 400W 2 OHMS RCA AMPLIFICADOR
          </h2>
          <img className="mod2" src={modulogif}></img>
          <div className="divpics">
            <img className="pics" src={modulo4}></img>
            <img className="pics" src={modulo5}></img>
            <img className="pics" src={modulo6}></img>
          </div>
          <h2 className="buy">R$ 286,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o módulo de som TARAMPS TS400X4."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>: Taramps
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">LINHA:</h3>TS
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>TS400
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Branco
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>{" "}
              Carro/Caminhonete
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA:</h3> 13,8V
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE DE CANAIS:</h3>4
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CLASSE:</h3>D
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">ORIGEM:</h3>Brasil
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
