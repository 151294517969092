import original from "./images/original.png";
import entrega from "./images/entrega.png";

import exp from "./images/exp.png";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function Slide() {
  return (
    <div translate="no" className="divfullc">
      <AutoplaySlider play={true} cancelOnInteraction={false} interval={5000}>
        <div className="divcarousel">
          <h3 className="h3carousel">
            <img className="icons" src={original}></img>
          </h3>
          <h3 className="iconstext">PRODUTOS 100% ORIGINAIS</h3>
        </div>
        <div className="divcarousel">
          <h3 className="h3carousel">
            <img className="icons1" src={exp}></img>
          </h3>
          <h3 className="iconstext">EXPERIÊNCIA NO MERCADO</h3>
        </div>
        <div className="divcarousel">
          <h3 className="h3carousel">
            <img className="icons2" src={entrega}></img>
          </h3>
          <h3 className="iconstext">ENVIAMOS PARA TODO O BRASIL</h3>
        </div>
      </AutoplaySlider>
    </div>
  );
}
