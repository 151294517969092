import Header from "./Header";
import Logo from "./Logo";
import caixa1 from "./images/caixa1.png";
import caixa2 from "./images/caixa2.png";
import caixa3 from "./images/caixa3.png";
import caixag from "./images/caixag.gif";

import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";
import Hr from "./Hr";
export default function Caixa() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            CAIXA AMPLIFICADA EXCLUSIVE XC 200 TRAPEZIO 8 UNIVERSAL 200W
          </h2>
          <img className="caixa" src={caixag}></img>
          <div className="divpics">
            <img className="pics" src={caixa1}></img>
            <img className="pics" src={caixa2}></img>
            <img className="pics" src={caixa3}></img>
          </div>
          <h2 className="buy">R$ 489,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar a caixa amplificada exclusive XC 200."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Exclusive
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Trapézio
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LARGURA ALTO FALANTE:</h3>8'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO ALTO FALANTE:</h3>8'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">LARGURA:</h3>34CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">ALTURA:</h3>28CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">DIÂMETRO:</h3> 8'
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Preto
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM SIRENE:</h3>Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE ALTO FALANTES:</h3>1
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO ALTO FALANTE:</h3>Subwoofer
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">POTÊNCIA RMS:</h3>200W
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
