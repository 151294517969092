import Header from "./Header";
import Logo from "./Logo";
import tomada1 from "./images/tomada1.png";
import tomada2 from "./images/tomada2.png";
import tomada3 from "./images/tomada3.png";
import tomadag from "./images/tomadag.gif";

import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";

export default function Tomadas() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            TOMADA USB ENCAIXE ACENDEDOR 2 SAÍDAS 3.1A COR PRETO
          </h2>
          <img className="tomadas1" src={tomadag}></img>
          <div className="divpics">
            <img className="pics" src={tomada1}></img>
            <img className="pics" src={tomada2}></img>
            <img className="pics" src={tomada3}></img>
          </div>
          <h2 className="buy">R$ 86,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar a tomada/acendedor usb URBANROAD."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Urbanroad
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3> 3.1A (Saída 2.1A + Saída
              1.0A)
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE CONECTOR:</h3> USB padrão
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COM GPS:</h3> SIM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM CABO:</h3> Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VELOCIDADE DA CARGA:</h3>Turbo
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CAPACIDADE DE BATERIA:</h3>25000 mAh
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VOLTAGEM DE ENTRADA:</h3>12/24V
              (BIVOLT)
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">VOLTAGEM DE SAÍDA:</h3>5V
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE DE PORTAS:</h3>2
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
