import Header from "./Header";
import Logo from "./Logo";
import bloq1 from "./images/bloq1.png";
import bloq2 from "./images/bloq2.png";
import bloq3 from "./images/bloq3.png";
import bloqg from "./images/bloqg.gif";

import Promo from "./Promopage";
import Infos from "./Infos";
import Scroll from "./Scroll";

export default function Bloqueador() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            BLOQUEADOR CORTA CORRENTE ANTI FURTO AUTOMOTIVO 12V FKS
          </h2>
          <img className="bloq" src={bloqg}></img>
          <div className="divpics">
            <img className="pics" src={bloq1}></img>
            <img className="pics" src={bloq2}></img>
            <img className="pics" src={bloq3}></img>
          </div>
          <h2 className="buy">R$ 138,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o bloqueador anti furto FKS."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>FKS Alarmes
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">NÚMERO DE PEÇA:</h3> MAF112TC
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">ORIGEM:</h3> Brasil
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
