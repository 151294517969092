import { FaWhatsapp } from "react-icons/fa";
import instalogo from "./images/instalogo.png";
export default function Infos() {
  return (
    <div translate="no" className="allinfo">
      <div className="divinfo">
        <h2 className="infos">REDES SOCIAIS</h2>
        <h2 className="h2insta">
          <a href="https://www.instagram.com/jtquality_/" target="blank">
            <img src={instalogo} className="insta" />
          </a>
        </h2>
      </div>
      <div className="divinfo">
        <h2 className="infos">PRECISA DE AJUDA?</h2>
        <a
          href="https://wa.me/5531985147100?text=Olá. Gostaria de ajuda!"
          target="blank"
        >
          <button className="btnwpp">
            WHATSAPP
            <FaWhatsapp className="wpp" />
          </button>
        </a>
      </div>
      <div className="direitos">© 2024 JT Quality.</div>
    </div>
  );
}
