import "./App.css";
import Header from "./Header";
import Title from "./Title";

import Message from "./Message";
import Promo from "./Promo";
import Infos from "./Infos";

import Slide from "./Slide";

import Logo from "./Logo";
import Gif from "./Gif";
import Lancamento from "./Lancamento";
import Scroll from "./Scroll";
import Mult from "./Mult";
import Multbtn from "./Multbtn";
import Reboque from "./Reboque";

import Multbtnr from "./Multbtnr";

function App() {
  return (
    <div translate="no" className="App">
      <Header />
      <Logo />
      <Title />
      <Gif />
      <Lancamento />
      <Mult />
      <Multbtn />
      <Reboque />
      <Multbtnr />

      <Message />
      <Slide />

      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}

export default App;
