import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import j from "./images/j.png";
import Select from "./Select";
import { IoMdHome } from "react-icons/io";

export default function Header() {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 65);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const [currentPage, setCurrentPage] = useState(0);

  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);
  return (
    <nav translate="no" className={`${sticky ? "sticky" : ""}`}>
      <div className="divnav">
        <Link className="link" to="/">
          <IoMdHome className="io" />
        </Link>

        <Select />

        <div className="divimg">
          <img className="imglogo" src={j} />
        </div>
      </div>
    </nav>
  );
}
