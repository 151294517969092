export default function Multbtnr() {
  return (
    <div translate="no">
      <div className="divlancamento">
        <a
          href="https://wa.me/5531985147100?text=Olá. Gostaria de verificar os modelos disponíveis de reboques União."
          target="blank"
          className="producttext"
          to="/produtos"
        >
          VERIFICAR MODELOS
        </a>
      </div>
    </div>
  );
}
