import Header from "./Header";
import Logo from "./Logo";
import sensor1 from "./images/sensor1.png";
import sensor2 from "./images/sensor2.png";
import sensor3 from "./images/sensor3.png";
import sensorg from "./images/sensorg.gif";

import Promo from "./Promopage";
import Infos from "./Infos";
import Select from "./Select";
import Scroll from "./Scroll";

export default function Sensor() {
  return (
    <div translate="no">
      <Header />
      <Logo />

      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            KIT SENSOR ESTACIONAMENTO 4 SENSORES DISPLAY CORES LED SONORO
          </h2>
          <img className="sensor1" src={sensorg}></img>
          <div className="divpics">
            <img className="pics" src={sensor1}></img>
            <img className="pics" src={sensor2}></img>
            <img className="pics" src={sensor3}></img>
          </div>
          <h2 className="buy">R$ 151,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o kit sensor de estacionamento TECHONE."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Tech One
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Universal
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE VEÍCULOS:</h3>{" "}
              Carro/Caminhonete
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">TELA DIGITAL:</h3>Sim
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM CÂMERA:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COM BUZZER:</h3>Sim
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
