import caboone from "./images/caboone.gif";
import cabo1 from "./images/cabo1.png";
import cabo2 from "./images/cabo2.png";
import cabo3 from "./images/cabo3.png";
import cabo4 from "./images/cabo4.png";
import cabo5 from "./images/cabo5.png";
import cabo6 from "./images/cabo6.png";
import cabo7 from "./images/cabo7.png";
import cabo8 from "./images/cabo8.png";
import cabo9 from "./images/cabo9.png";
import cabo10 from "./images/cabo10.png";
import cabo11 from "./images/cabo11.png";
import cabo12 from "./images/cabo12.png";
import cabo13 from "./images/cabo13.png";
import cabo14 from "./images/cabo14.png";
import cabo15 from "./images/cabo15.png";
import cabo16 from "./images/cabo16.png";
import cabo17 from "./images/cabo17.png";
import cabo18 from "./images/cabo18.png";
import cabo19 from "./images/cabo19.png";
import cabo20 from "./images/cabo20.png";
import cabo21 from "./images/cabo21.png";
import cabof from "./images/cabof.gif";
import caboftwo from "./images/caboftwo.gif";
import caboblue from "./images/caboblue.gif";
import cabowhite from "./images/cabowhite.gif";
import caboblack from "./images/caboblack.gif";
import cabotwo from "./images/cabotwo.gif";
import Header from "./Header";
import Logo from "./Logo";
import Promo from "./Promopage";
import Infos from "./Infos";

import Hr from "./Hr";
import Scroll from "./Scroll";
export default function Cabos() {
  return (
    <div translate="no">
      <Header />
      <Logo />
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            CABO PARALELO PVC FLEXIVEL COBRE 100M 2X2 50MM 2X12
          </h2>

          <img className="picpioneer1" src={caboone}></img>
          <div className="divpics">
            <img className="pics" src={cabo1}></img>
            <img className="pics" src={cabo2}></img>
            <img className="pics" src={cabo3}></img>
          </div>
          <h2 className="buy">R$ 284,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o cabo paralelo pvc cobre flexível CMIX 100M."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Cmix
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Paralelo 2 x 2.5
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE CABO:</h3>Paralelo
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Cristal Tarja Roxa
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">FORMATO DE VENDA:</h3>Unidade
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>100M
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE PRODUTO:</h3>Cabo
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">DIÂMETRO:</h3>2.5MM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE ENTRADA:</h3>
              2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE SAÍDA:</h3>2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>Fio
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>Fio
            </h3>
          </div>
          <Hr />
        </div>
      </div>
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            CABO BICOLOR PARALELO CRISTAL POLARIZADO 100M 2X 1,5MM
          </h2>

          <img className="picpioneer2" src={cabotwo}></img>
          <div className="divpics">
            <img className="pics" src={cabo4}></img>
            <img className="pics" src={cabo5}></img>
            <img className="pics" src={cabo6}></img>
          </div>
          <h2 className="buy">R$ 239,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o cabo bicolor paralelo cristal polarizado 100M."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Streaming Cable
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Biocolor Paralelo Cristal
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE CABO:</h3>AWG
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Cristal
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">FORMATO DE VENDA:</h3>Unidade
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>100M
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE PRODUTO:</h3>Cabo
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">DIÂMETRO:</h3>1.5MM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TAMANHO AWG:</h3>14
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE ENTRADA:</h3>
              0
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE SAÍDA:</h3>0
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>Não
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MATERIAL REVESTIMENTO:</h3>Não
            </h3>
          </div>
          <Hr />
        </div>
      </div>
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            CABO RCA DUPLA BLINDAGEM COBRE 5M AZUL
          </h2>

          <img className="picpioneer3" src={caboblue}></img>
          <div className="divpics">
            <img className="pics" src={cabo7}></img>
            <img className="pics" src={cabo8}></img>
            <img className="pics" src={cabo9}></img>
          </div>
          <h2 className="buy">R$ 87,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o cabo rca dupla blindagem cobre azul 5M."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Techone Svart
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Som amplificador
              automotivo
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE CABO:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Azul
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">FORMATO DE VENDA:</h3>Unidade
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>5M
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE PRODUTO:</h3>Cabo
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE ENTRADA:</h3>
              2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE SAÍDA:</h3>2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>Macho
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>Macho
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>RCA
            </h3>
          </div>
          <Hr />
        </div>
      </div>
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            CABO RCA BLINDADO P/ MÓDULO AMPLIFICADOR SOM AUTOMOTIVO 5M
          </h2>

          <img className="picpioneer4" src={caboblack}></img>
          <div className="divpics">
            <img className="pics" src={cabo10}></img>
            <img className="pics" src={cabo11}></img>
            <img className="pics" src={cabo12}></img>
          </div>
          <h2 className="buy">R$ 49,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o cabo rca blindado para módulo aplificador automotivo 5M ."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Som automotivo
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE CABO:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Preto
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">FORMATO DE VENDA:</h3>Unidade
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>5M
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE PRODUTO:</h3>Cabo
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE ENTRADA:</h3>
              2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE SAÍDA:</h3>2
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>RCA
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MATERIAL REVESTIMENTO:</h3>Borracha
            </h3>
          </div>
          <Hr />
        </div>
      </div>
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            CABO RCA TARAMPS BLINDADO PLUG BANHADO OURO 1M
          </h2>

          <img className="picpioneer5" src={cabowhite}></img>
          <div className="divpics">
            <img className="pics" src={cabo13}></img>
            <img className="pics" src={cabo14}></img>
            <img className="pics" src={cabo15}></img>
          </div>
          <h2 className="buy">R$ 60,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o cabo rca plug TARAMPS 1M."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Taramps
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>RCA
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE CABO:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Branco
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">FORMATO DE VENDA:</h3>Unidade
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>1M
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE PRODUTO:</h3>Cabo
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE ENTRADA:</h3>
              2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE SAÍDA:</h3>2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>Macho
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>Macho
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>RCA
            </h3>
          </div>
          <Hr />
        </div>
      </div>
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">CABO Y RCA FÊMEA X2 RCA MACHO 20CM</h2>

          <img className="picpioneer6" src={cabof}></img>
          <div className="divpics">
            <img className="pics" src={cabo16}></img>
            <img className="pics" src={cabo17}></img>
            <img className="pics" src={cabo18}></img>
          </div>
          <h2 className="buy">R$ 38,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o cabo rca fêmea X2 rca macho VINIK 20CM."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Vinik
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>7908020916840
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE CABO:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Preto
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">FORMATO DE VENDA:</h3>Unidade
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>20CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE PRODUTO:</h3>Cabo Adaptador
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE ENTRADA:</h3>
              1
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE SAÍDA:</h3>2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>Macho
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>Macho
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>RCA
            </h3>
          </div>
          <Hr />
        </div>
      </div>
      <div className="fdivproductp">
        <div className="divproductp">
          <h2 className="textproductp">
            {" "}
            PAR CABOS Y RCA BLINDADO TECHONE 2 MACHOS AZUL
          </h2>

          <img className="picpioneer7" src={caboftwo}></img>
          <div className="divpics">
            <img className="pics" src={cabo19}></img>
            <img className="pics" src={cabo20}></img>
            <img className="pics" src={cabo21}></img>
          </div>
          <h2 className="buy">R$ 148,00</h2>
          <a
            href="https://wa.me/5531985147100?text=Olá. Gostaria de comprar o par de cabos rca blindado Techone."
            target="blank"
            className="buybtn"
          >
            COMPRAR PRODUTO
          </a>
          <div className="descrip">
            <h3 className="h3descrip">
              <h3 className="descripbold">MARCA:</h3>Techone Svart
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MODELO:</h3>Som Amplificador
              Automotivo
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE CABO:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">COR:</h3>Azul
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">FORMATO DE VENDA:</h3>Kit
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">UNIDADE POR KIT:</h3>2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">COMPRIMENTO:</h3>26CM
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">TIPO DE PRODUTO:</h3>Cabo
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">DIÂMETRO:</h3>5MM
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE ENTRADA:</h3>
              2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">QUANTIDADE CONECTORES DE SAÍDA:</h3>2
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>Fêmea
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>Macho
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE ENTRADA:</h3>RCA
            </h3>

            <h3 className="h3descrip">
              <h3 className="descripbold">CONECTOR DE SAÍDA:</h3>RCA
            </h3>
            <h3 className="h3descrip">
              <h3 className="descripbold">MATERIAL REVESTIMENTO:</h3>Ouro
            </h3>
          </div>
        </div>
      </div>
      <Promo />
      <Infos />
      <Scroll />
    </div>
  );
}
